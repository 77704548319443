import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ISSUE_STATE } from "@/types/types";
import { FieldName } from "@/types/tables/fieldName";
import {
  useGetIssuesQuery,
  useUnlockIssueMutation,
  useCancelIssueMutation,
} from "@/store/issuesRequest/issuesApi";
import { refetchIssue, setRefetch } from "@/store/issuesRequest/refetchSlice";
import { Table, TableHead, TableBody } from "@/components/Table";
import { Button } from "@/components/Buttons";
import IssueInfo from "@/pages/IssuePage/IssueInfo/IssueInfo";
import { setDetailsFetch } from "@/store/issuesRequest/detailsSlice";

const fieldNames: FieldName[] = [
  {
    name: "issueRequestId",
    alias: "Id",
    type: "number",
  },
  {
    name: "type",
    alias: "Type",
    type: "string",
  },
  {
    name: "weekNumber",
    alias: "Week",
    type: "string",
  },
  {
    name: "created",
    alias: "Started",
    type: "date",
  },
  {
    name: "finished",
    alias: "Finished",
    type: "date",
  },
  {
    name: "createdBy",
    alias: "Creator",
    type: "string",
  },
  {
    name: "state",
    alias: "State",
    type: "string",
  },
  {
    name: "issueId",
    alias: "Issue",
    type: "number",
  },
  {
    name: "Action",
    alias: "Action",
    type: "action",
  },
];

const textColor = (element: ISSUE_STATE) => {
  switch (element) {
    case ISSUE_STATE.New:
      return "#0734f3";
    case ISSUE_STATE.InProgress:
      return "#0734f3";
    case ISSUE_STATE.Failed:
      return "#f30707";
    case ISSUE_STATE.Completed:
      return "#1cad10";
    default:
      return "#000000";
  }
};

const IssueTable: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: issueData,
    isLoading: issueIsLoading,
    isError: issueIsError,
    refetch: issueRefetch,
  } = useGetIssuesQuery(undefined, { pollingInterval: 60000 });

  const [openRequestId, setOpenRequestId] = React.useState<number>(-1);

  const [unlockIssue] = useUnlockIssueMutation();
  const [cancelIssue] = useCancelIssueMutation();

  // need for re-fetch list issues after create new issue
  const refetchIssues = useSelector(refetchIssue);

  useEffect(() => {
    if (refetchIssues) {
      issueRefetch();
      dispatch(setRefetch(false));
    }
  }, [refetchIssues, issueRefetch, dispatch]);

  return (
    <Table
      title={"Issues"}
      testId={"issues-table"}
      isLoading={issueIsLoading}
      isError={issueIsError}
      refetchData={() => {
        issueRefetch();
      }}
    >
      <TableHead expandAction={true} fieldNames={fieldNames} />
      {issueData && (
        <TableBody
          data={issueData}
          hover={true}
          testTitle={"issues"}
          fieldNames={fieldNames}
          textColor={(column, value) =>
            column.name === "state" ? textColor(value) : value
          }
          actions={(index) => {
            const buttons: React.ReactElement[] = [];
            if (
              issueData[index].state !== ISSUE_STATE.Canceled &&
              issueData[index].state !== ISSUE_STATE.Completed &&
              issueData[index].state !== ISSUE_STATE.Failed
            ) {
              buttons.push(
                <Button
                  key={`cancel-${index}`}
                  color={"error"}
                  title={"Cancel"}
                  onClickState={() => {
                    cancelIssue(issueData[index].issueRequestId.toString());
                  }}
                />
              );
            }

            if (issueData[index].state === ISSUE_STATE.ReadyForTesting) {
              buttons.push(
                <Button
                  key={`unlock-${index}`}
                  title={"Unlock"}
                  color={"error"}
                  onClickState={() => {
                    unlockIssue(issueData[index].issueRequestId.toString());
                  }}
                />
              );
            }
            return buttons;
          }}
          expandAction={(index) => {
            if (issueData[index].issueRequestId === openRequestId) {
              setOpenRequestId(-1);
              return;
            }
            setOpenRequestId(issueData[index].issueRequestId);
            setOpenRequestId(issueData[index].issueRequestId);
            dispatch(
              setDetailsFetch({
                fetchId: issueData[index].issueRequestId,
                message: "",
              })
            );
          }}
          conditionParameters={{
            field: "issueRequestId",
            value: openRequestId,
            viewField: "issueId",
          }}
          extraCellElement={() => {
            return <IssueInfo />;
          }}
        />
      )}
    </Table>
  );
};

export default IssueTable;
