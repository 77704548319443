import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@/utilities/store/customFetchBase";
import { ChartDatabaseRequest } from "@/types/chartdata/request";
import ReponseChartData from "@/types/chartdata/response";
import ChartHistoryResponse from "@/types/chartdata/charthistory";

export const chartDataApi = createApi({
  reducerPath: "chartDataApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    createTableData: build.mutation<ReponseChartData, ChartDatabaseRequest>({
      query: (body) => ({
        url: "/crt-data",
        method: "POST",
        body,
      }),
    }),
    getChartHistory: build.query<ChartHistoryResponse[], string>({
      query: (fileName) => ({
        url: `/crt-data/${fileName}/history`,
        method: "GET",
      }),
    }),
  }),
});

export const { useCreateTableDataMutation, useGetChartHistoryQuery } =
  chartDataApi;
