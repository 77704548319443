import React from "react";
import { Box } from "@mui/material";

interface PanelContainerProps {
  flexDirection?: "row" | "column";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  children: React.ReactNode;
}

const PanelContainer: React.FC<PanelContainerProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: props.flexDirection || "column",
        justifyContent: props.justifyContent || "center",
        m: 1,
      }}
    >
      {props.children}
    </Box>
  );
};

export default PanelContainer;
