import React from "react";
import { Typography } from "@mui/material";

interface StringLogProps {
  logText: string;
}

const StringLog: React.FC<StringLogProps> = ({ logText }) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ whiteSpace: "pre-line" }}
    >
      {logText}
    </Typography>
  );
};

export default StringLog;
