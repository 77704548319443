import React from "react";
import { useSelector } from "react-redux";
import { useGetChartHistoryQuery } from "@/store/chartDataRequest/chartDataApi";
import {
  selectedChartByFileName,
  selectedChartByUid,
} from "@/store/chartDataRequest/selectedChartSlice";
import { Table, TableHead, TableBody } from "@/components/Table";
import ChartHistoryResponse from "@/types/chartdata/charthistory";
import { FieldName } from "@/types/tables/fieldName";

const fieldNames: FieldName[] = [
  {
    name: "id",
    alias: "ID",
    type: "number",
  },
  {
    name: "uid",
    alias: "UID",
    type: "number",
  },
  {
    name: "description",
    alias: "Description",
    type: "string",
  },
  {
    name: "latB",
    alias: "Lat B",
    type: "number",
  },
  {
    name: "latE",
    alias: "Lat E",
    type: "number",
  },
  {
    name: "lonB",
    alias: "Lon B",
    type: "number",
  },
  {
    name: "lonE",
    alias: "Lon E",
    type: "number",
  },
  {
    name: "scale",
    alias: "Scale",
    type: "number",
  },
  {
    name: "type",
    alias: "Type",
    type: "number",
  },
  {
    name: "issued",
    alias: "Issued",
    type: "date",
  },
  {
    name: "printed",
    alias: "Printed",
    type: "date",
  },
];

const tableData = (data: ChartHistoryResponse[], uid: number) => {
  return data.filter((item: ChartHistoryResponse) => {
    if (
      item.operations.length > 0 &&
      item.operations.every((el) => el.uid === uid)
    ) {
      return item;
    } else return null;
  });
};

const СhartDBHistory = () => {
  const selectedByFileName = useSelector(selectedChartByFileName);
  const selectedByUid = useSelector(selectedChartByUid);

  const { data, isError, isFetching } = useGetChartHistoryQuery(
    selectedByFileName as string,
    { skip: !selectedByFileName }
  );

  const chartHistory = React.useMemo(() => {
    return data ? tableData(data, selectedByUid as number)[0] : null;
  }, [data, selectedByUid]);

  return (
    <>
      <Table
        title={`Operations ${selectedByFileName}`}
        testId={"table-history"}
        isLoading={isFetching}
        isError={isError}
      >
        <TableHead fieldNames={fieldNames} />
        {chartHistory && (
          <TableBody fieldNames={fieldNames} data={chartHistory.operations} />
        )}
      </Table>
    </>
  );
};

export default СhartDBHistory;
