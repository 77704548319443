import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Provider } from "react-redux";
import { index } from "./store";

const theme = createTheme();

function App() {
  return (
    <Provider store={index}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout />
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;
