import React from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { NavLink } from "react-router-dom";
import RouteTab from "@/types/routes/routes";
import routesDict from "@/utilities/routes/routesDict";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SignInButton, SignOutButton } from "@/components/NavBar";

export default function Header() {
  const { instance } = useMsal();
  const userAccount = instance.getAllAccounts()[0];
  const userName = userAccount?.name;
  const userEmail = userAccount?.username;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ height: "64px" }}>
      <Toolbar>
        <img
          style={{ maxHeight: "64px", position: "relative", bottom: "3px" }}
          src={process.env.PUBLIC_URL + "/wartsila-logo.svg"}
          alt="Wärtsilä Logo"
        ></img>
        <AuthenticatedTemplate>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {routesDict.map((page) => (
              <Link
                key={page.key}
                component={NavLink}
                to={page.path}
                color="black"
                underline="none"
                variant="button"
                data-testid={`link-${page.key}`}
                sx={{ fontSize: "large", marginLeft: "2rem" }}
              >
                <Typography color={"white"}> {page.title} </Typography>
              </Link>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="open drawer"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routesDict.map((page: RouteTab) => (
                <Link
                  key={page.key}
                  component={NavLink}
                  to={page.path}
                  color="black"
                  underline="none"
                  variant="button"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography color={"black"} textAlign="center">
                      {page.title}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
        </AuthenticatedTemplate>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AuthenticatedTemplate>
            <Typography
              sx={{
                fontSize: "0.75rem",
                marginRight: "1rem",
              }}
            >
              {userName ? userName + " (" + userEmail + ")" : userEmail}
            </Typography>
            <SignOutButton />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignInButton />
          </UnauthenticatedTemplate>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
