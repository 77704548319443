import React from "react";
import { FieldName } from "@/types/tables/fieldName";
import { TableHead, TableRow } from "@mui/material";
import TableHeaderCell from "@/components/Table/TableHeaderCell";
import { Sort } from "@/types/chartdata/request";

interface TableHeaderProps {
  fieldNames: FieldName[];
  sortDirection?: Sort;
  handleSort?: (sortField: string) => void;
  expandAction?: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  fieldNames,
  sortDirection,
  handleSort,
  expandAction,
}) => {
  return (
    <TableHead>
      <TableRow>
        {expandAction && (
          <TableHeaderCell
            key={"expand"}
            fieldName={{ name: "expandAction", alias: "", type: "action" }}
          />
        )}
        {fieldNames.map((fieldName, index) => (
          <TableHeaderCell
            key={index}
            sortDirection={
              sortDirection
                ? sortDirection.by === fieldName.name
                  ? sortDirection.order
                  : "unused"
                : undefined
            }
            handleSort={handleSort ? handleSort : undefined}
            fieldName={fieldName}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
