import React from "react";
import { FieldName } from "@/types/tables/fieldName";
import { useGetIssueSummaryQuery } from "@/store/issuesRequest/issuesApi";
import { Table, TableHead, TableBody } from "@/components/Table";

const fieldNames: FieldName[] = [
  {
    name: "name",
    alias: "Name",
    type: "string",
  },
  {
    name: "details",
    alias: "Details",
    type: "string",
  },
  {
    name: "infoCreated",
    alias: "Creation Date",
    type: "date",
  },
];

const IssueSummary: React.FC = () => {
  const {
    data: IssueSummaryData,
    isLoading: IssueSummaryIsLoading,
    isError: IssueSummaryIsError,
  } = useGetIssueSummaryQuery(undefined, { pollingInterval: 60000 });

  return (
    <Table
      title={"Issues Summary"}
      testId={"summary-table"}
      isLoading={IssueSummaryIsLoading}
      isError={IssueSummaryIsError}
    >
      <TableHead fieldNames={fieldNames} />
      {IssueSummaryData && IssueSummaryData.length > 0 && (
        <TableBody data={IssueSummaryData} fieldNames={fieldNames}></TableBody>
      )}
    </Table>
  );
};

export default IssueSummary;
