import { FC } from "react";
import { FormControl, TextField } from "@mui/material";

export interface TextForm {
  label: string;
  disabled: boolean;
  valueChange: (text: string | number) => void;
  value?: string | number | null;
  error?: () => boolean;
  helperText?: () => string | undefined;
}

const TextControl: FC<TextForm> = (props: TextForm) => {
  return (
    <FormControl sx={{ mb: 1 }} size="small">
      <TextField
        id={`${props.label}-id`}
        label={props.label}
        disabled={props.disabled}
        data-testid={`text-${props.label}-id`}
        defaultValue={props.value}
        variant="standard"
        error={props.error ? props.error() : false}
        inputProps={{ "data-testid": `${props.label}-input` }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.valueChange(event.target.value)
        }
        helperText={props.helperText ? props.helperText() : undefined}
      />
    </FormControl>
  );
};

export default TextControl;
