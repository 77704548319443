import { FC } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ISSUE_TYPE } from "../../../types/types";

type ValueType = string | number | ISSUE_TYPE;

export interface SelectForm {
  label: string;
  disabled: boolean;
  selected: ValueType;
  options: {
    key: string;
    value: ValueType;
  }[];
  onChange: (val: ValueType) => void;
  error: boolean;
  disabledItem?: (value: string) => boolean;
}

const SelectControl: FC<SelectForm> = (props: SelectForm) => {
  const handleChange = (event: any) => {
    const val: ValueType = event.target.value as ValueType;
    props.onChange(val);
  };

  return (
    <FormControl sx={{ mb: 1 }} size="small" disabled={props.disabled}>
      <InputLabel id={`${props.label}-id`}>{props.label}</InputLabel>
      <Select
        labelId={`${props.label}-id`}
        value={props.selected}
        label={props.label}
        onChange={handleChange}
        error={props.error}
        MenuProps={{
          PaperProps: { style: { maxHeight: 300 } },
        }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.key}
            value={option.value}
            disabled={
              props.disabledItem
                ? props.disabledItem(option.value as string)
                : false
            }
          >
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectControl;
