import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import TokenBE from "@/types/auth/tokenInf";

const initialState: TokenBE = {
  backendToken: null,
  expiresOn: null,
};

const authTokenInf = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.backendToken = action.payload;
    },
    setExpiresOn: (state, action) => {
      state.expiresOn = action.payload;
    },
  },
});

export const { setToken } = authTokenInf.actions;
export const { setExpiresOn } = authTokenInf.actions;
export default authTokenInf.reducer;

export const selectToken = (state: RootState) => state.auth.backendToken;
export const selectExpiresOn = (state: RootState) => state.auth.expiresOn;
