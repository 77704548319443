import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";

interface refetchTrigger {
  refetch: boolean;
}

const initialState: refetchTrigger = {
  refetch: false,
};

const refetchSlice = createSlice({
  name: "blockerForm",
  initialState,
  reducers: {
    setRefetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { setRefetch } = refetchSlice.actions;
export default refetchSlice.reducer;
export const refetchIssue = (state: RootState) => state.refetchIssue.refetch;
