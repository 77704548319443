import React from "react";
import { IconButton as MuiIconButton } from "@mui/material";

interface ButtonProps {
  onClickState: () => void;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  children: React.ReactElement;
}

const IconButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const handleValueChange = () => {
    props.onClickState();
  };

  return (
    <MuiIconButton onClick={handleValueChange} color={props.color}>
      {props.children}
    </MuiIconButton>
  );
};

export default IconButton;
