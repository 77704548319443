import React from "react";
import { useSelector } from "react-redux";
import { FieldName } from "@/types/tables/fieldName";
import { fetchDetail } from "@/store/issuesRequest/detailsSlice";
import { useLazyGetIssueStatusesQuery } from "@/store/issuesRequest/issuesApi";
import { Table, TableBody } from "@/components/Table";
import { ProgressBar } from "@/components/StatusBar";
import { Accordion } from "@/components/Accordion";
import { StringLog } from "@/components/Text";

const fieldNames: FieldName[] = [
  {
    name: "operationInfo",
    alias: "Operation Info",
    type: "string",
  },
  {
    name: "operationType",
    alias: "Operation Type",
    type: "string",
  },
  {
    name: "updateTime",
    alias: "Update Time",
    type: "date",
  },
];

const IssueInfo: React.FC = () => {
  const fetchDetails = useSelector(fetchDetail);

  const [
    fetchIssueStatuses,
    {
      data: issueStatuses,
      isLoading: isLoadingIssueStatuses,
      isError: isErrorIssueStatuses,
    },
  ] = useLazyGetIssueStatusesQuery();

  React.useEffect(() => {
    if (fetchDetails.details.fetchId < 0) return;
    fetchIssueStatuses(fetchDetails.details.fetchId);
  }, [fetchDetails, fetchIssueStatuses]);

  return (
    <>
      <Table
        title={"Issue Info"}
        testId={"info-table"}
        isLoading={isLoadingIssueStatuses}
        isError={isErrorIssueStatuses}
        refetchData={() => {
          fetchIssueStatuses(fetchDetails.details.fetchId);
        }}
        maxHeight={"calc(100vh - 148px - 64px - 188px)"}
      >
        {issueStatuses && (
          <TableBody
            data={issueStatuses}
            fieldNames={fieldNames}
            conditionParameters={{
              field: "operationType",
              value: "Process",
              viewField: "progress",
            }}
            extraCellElement={(value) => {
              const val = value as number;
              return <ProgressBar value={val} />;
            }}
            alignCell={"left"}
          />
        )}
      </Table>
      {fetchDetails.details && (
        <Accordion title="Issue Log">
          <StringLog logText={fetchDetails.details.message as string} />
        </Accordion>
      )}
    </>
  );
};

export default IssueInfo;
