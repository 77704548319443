import React from "react";
import { IssueSummaryRow, Issue, IssueStatus } from "@/types/types";
import ChartDatabase from "@/types/chartdata/chartdata";
import { TableBody, TableRow, TableCell, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { IconButton } from "@/components/Buttons";
import { ValueInComponent } from "@/components/Text";
import { FieldName } from "@/types/tables/fieldName";
import { ChartDatabaseRequestFields } from "@/types/chartdata/request";

interface ConditionParameters {
  field: string;
  value: string | number;
  viewField: string | number;
}

type DataInterface =
  | ChartDatabase
  | IssueSummaryRow
  | Issue
  | IssueStatus
  | ChartDatabaseRequestFields;

interface TableBodyProps {
  data: Array<DataInterface>;
  fieldNames: FieldName[];
  testTitle?: string;
  hover?: boolean;
  selectAction?: (selectedIndex: number) => void;
  expandAction?: (selectedIndex: number) => void;
  conditionParameters?: ConditionParameters;
  extraCellElement?: (value: string | number) => React.ReactElement;
  textColor?: (column: FieldName, value: any) => string;
  alignCell?: string;
  actions?: (elementIndex: number) => React.ReactElement[];
}

const TableBodyMain: React.FC<TableBodyProps> = ({
  data,
  fieldNames,
  testTitle,
  hover = false,
  selectAction = undefined,
  conditionParameters = undefined,
  extraCellElement = undefined,
  textColor = undefined,
  alignCell = "center",
  actions,
  expandAction,
}) => {
  const [selectIndex, setSelectIndex] = React.useState(0);

  const handleSelect = (index: number) => {
    setSelectIndex(index);
  };

  React.useEffect(() => {
    if (!selectAction || data.length === 0) return;
    selectAction(selectIndex);
  }, [selectIndex, selectAction, data]);

  return (
    <TableBody>
      {data.map((element: any, indexElement) => (
        <React.Fragment key={indexElement}>
          <TableRow
            key={indexElement}
            hover={hover}
            data-testid={`${testTitle}-table-row-${indexElement}`}
            selected={!!(selectAction && selectIndex === indexElement)}
            onClick={
              selectAction ? () => handleSelect(indexElement) : undefined
            }
            sx={{
              ...(conditionParameters &&
                element[conditionParameters.field] ===
                  conditionParameters.value && {
                  "& > *": { borderBottom: "unset" },
                }),
            }}
          >
            {expandAction && (
              <TableCell key={"expandColumn"}>
                <IconButton onClickState={() => expandAction(indexElement)}>
                  {conditionParameters &&
                  element[conditionParameters.field] ===
                    conditionParameters.value &&
                  extraCellElement ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </TableCell>
            )}
            {fieldNames.map((fieldName, index) => (
              <TableCell key={index}>
                {fieldName.type === "action" ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {actions && actions(indexElement)}
                  </Box>
                ) : (
                  <ValueInComponent
                    type={fieldName.type}
                    value={element[fieldName.name]}
                    color={
                      textColor && textColor(fieldName, element[fieldName.name])
                    }
                    align={alignCell}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
          {conditionParameters &&
            element[conditionParameters.field] === conditionParameters.value &&
            extraCellElement && (
              <TableRow>
                <TableCell colSpan={12}>
                  {extraCellElement(element[conditionParameters.viewField])}
                </TableCell>
              </TableRow>
            )}
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export default TableBodyMain;
