import React from "react";
import { TableFooter, TableRow, TablePagination } from "@mui/material";

interface TableFooterMainProps {
  totalRows: number;
  colSpan?: number;
  pageOrigin: number;
  rowsPerPageOrigin: number;
  rowsPerPageOptions: Array<number | { value: number; label: string }>;
  changePage?: (newPage: number) => void;
  changeRowsPerPage?: (rowsPerPage: number) => void;
}

const TableFooterMain: React.FC<TableFooterMainProps> = ({
  totalRows,
  colSpan = 12,
  pageOrigin = 0,
  rowsPerPageOrigin,
  rowsPerPageOptions = [20, 35, 50],
  changePage,
  changeRowsPerPage,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={colSpan}
          count={totalRows}
          page={pageOrigin}
          rowsPerPage={rowsPerPageOrigin}
          onPageChange={(event: unknown, newPage) => {
            changePage && changePage(newPage);
          }}
          onRowsPerPageChange={(rowsPerPage) => {
            changeRowsPerPage &&
              changeRowsPerPage(parseInt(rowsPerPage.target.value, 10));
          }}
        />
      </TableRow>
    </TableFooter>
  );
};

export default TableFooterMain;
