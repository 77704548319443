import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";

interface Details {
  details: {
    fetchId: number;
    message: string | null;
  };
}

const initialState: Details = {
  details: {
    fetchId: -1,
    message: null,
  },
};

const detailsSlice = createSlice({
  name: "detailsFetch",
  initialState,
  reducers: {
    setDetailsFetch: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const { setDetailsFetch } = detailsSlice.actions;
export default detailsSlice.reducer;
export const fetchDetail = (state: RootState) => state.detailsFetch;
