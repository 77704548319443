import ChartDatabase from "@/types/chartdata/chartdata";

export enum SORT_DIRECTION {
  "Asc" = "Asc",
  "Desc" = "Desc",
}

export type ChartDatabaseFields = keyof ChartDatabase;

export type ChartDatabaseRequestFields = Partial<
  Record<ChartDatabaseFields, string | number | null>
>;

export interface Sort {
  by: string;
  order: SORT_DIRECTION;
}

interface Page {
  size: number;
  number: number;
}

export type ChartDatabaseRequest = {
  filterBy: ChartDatabaseRequestFields;
  sort: Sort;
  page: Page;
};
