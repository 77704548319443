import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getCurrentWeek } from "@/utilities/share";
import { ISSUE_TYPE, ISSUE_STATUS, IssueCreationData } from "@/types/types";
import {
  useLazyCanCreateIssueQuery,
  IssueCreationDataRequest,
  useCreateIssueMutation,
} from "@/store/issuesRequest/issuesApi";
import { setRefetch } from "@/store/issuesRequest/refetchSlice";
import { Form, SelectControl } from "@/components/Form";
import { getWeekRange } from "@/utilities/share";
import {
  showWarningMessage,
  showErrorMessage,
  showSuccessMessage,
} from "@/utilities/toastMessage";

const validate = (issueCreationData: IssueCreationData) => {
  return issueCreationData.type && issueCreationData.status;
};

const IssueForm = () => {
  const currentWeekNumber = getCurrentWeek();

  const [currentWeek, setCurrentWeek] = useState<number>(currentWeekNumber);

  const [issueCreationData, setIssueCreationData] = useState<IssueCreationData>(
    {
      type: ISSUE_TYPE.Tx97,
      status: ISSUE_STATUS.Online,
      weekNumber: currentWeek,
    }
  );

  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();

  const [createIssue] = useCreateIssueMutation();
  const [checkCreateIssue, { isError: isCheckCreateIssueError }] =
    useLazyCanCreateIssueQuery();

  const submit = useCallback(() => {
    setDisabled(true);

    if (!validate(issueCreationData)) {
      showWarningMessage("Please fill in all fields");
      setDisabled(false);
      return;
    }

    const checkIssue = async () =>
      checkCreateIssue({
        type: issueCreationData.type,
        status: issueCreationData.status,
      } as IssueCreationDataRequest)
        .unwrap()
        .then((res) => {
          if (isCheckCreateIssueError) {
            showErrorMessage(
              "Internal server error occurred when creating a new issue"
            );
            return;
          }

          if (res.isBusy) {
            showWarningMessage(
              "Issue request can't be created because another issue request is already in progress"
            );
            return;
          }

          showSuccessMessage("Issue creation request submitted successfully");
          console.log("start issue creation");
          createIssue(issueCreationData);

          dispatch(setRefetch(true)); // refetch issues after creating a new issue
        });

    checkIssue().then(() => {
      setDisabled(false);
    });
  }, [
    dispatch,
    checkCreateIssue,
    isCheckCreateIssueError,
    createIssue,
    issueCreationData,
  ]);

  return (
    <Form
      title="Create Issue"
      disabled={disabled}
      buttonTitle="Create"
      submitForm={submit}
      testId={"issue-form"}
    >
      <SelectControl
        label="Week Number"
        disabled={disabled}
        selected={currentWeek}
        options={getWeekRange.map((weekNumber) => ({
          key: `week-${weekNumber}`,
          value: weekNumber,
        }))}
        onChange={(value) => {
          setCurrentWeek(value as number);
          setIssueCreationData({
            type: issueCreationData.type,
            status: issueCreationData.status,
            weekNumber: value as number,
          });
        }}
        error={getWeekRange.indexOf(issueCreationData.weekNumber) === -1}
      />
      <SelectControl
        label="Issue Type"
        disabled={disabled}
        selected={issueCreationData.type}
        options={[
          { value: ISSUE_TYPE.Tx97, key: ISSUE_TYPE.Tx97 },
          { value: ISSUE_TYPE.Senc, key: ISSUE_TYPE.Senc },
        ]}
        onChange={(value) => {
          setIssueCreationData({
            type: value as ISSUE_TYPE,
            status: issueCreationData.status,
            weekNumber: issueCreationData.weekNumber,
          });
        }}
        error={!issueCreationData.type}
      />
      <SelectControl
        label="Issue Status"
        disabled={disabled}
        selected={issueCreationData.status}
        options={[
          { value: ISSUE_STATUS.Online, key: ISSUE_STATUS.Online },
          { value: ISSUE_STATUS.Base, key: ISSUE_STATUS.Base },
        ]}
        onChange={(value) => {
          setIssueCreationData({
            type: issueCreationData.type,
            status: value as ISSUE_STATUS,
            weekNumber: issueCreationData.weekNumber,
          });
        }}
        error={!issueCreationData.status}
        disabledItem={(value) => {
          return value === ISSUE_STATUS.Base;
        }}
      />
    </Form>
  );
};

export default IssueForm;
