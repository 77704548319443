import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import {
  ChartDatabaseRequest,
  SORT_DIRECTION,
} from "@/types/chartdata/request";

export const initialState: ChartDatabaseRequest = {
  filterBy: {},
  sort: {
    by: "uid",
    order: SORT_DIRECTION.Asc,
  },
  page: {
    number: 1,
    size: 20,
  },
};

const chartDataRequestSlice = createSlice({
  name: "chartDataRequest",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filterBy = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setPagination: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { setSort } = chartDataRequestSlice.actions;
export const { setFilters } = chartDataRequestSlice.actions;
export const { setPagination } = chartDataRequestSlice.actions;
export default chartDataRequestSlice.reducer;
export const getRequestQuery = (state: RootState) => state.chartDataRequest;
