import IssueMain from "@/pages/IssuePage/IssueMain";
import RouteTab from "@/types/routes/routes";
import ChartDBMain from "@/pages/ChartDatabase/ChartDBMain";

const routesDict: Array<RouteTab> = [
  {
    key: "IssueTracker",
    title: "Issue Tracker",
    path: "/IssueTracker",
    enabled: true,
    component: IssueMain,
  },
  {
    key: "ChartDatabases",
    title: "Chart Databases",
    path: "/ChartDatabases",
    enabled: true,
    component: ChartDBMain,
  },
];

export default routesDict;
