import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { RootState } from "@/store";
import { setExpiresOn, setToken } from "@/store/authBackend/tokenInf";
import { Mutex } from "async-mutex";
import { API_URL } from "@/constants";
import { getNewToken } from "./getNewToken";

export const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.backendToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  const dateExpires = (api.getState() as RootState).auth.expiresOn;
  let timeNow = new Date().getTime();
  let result;
  if ((dateExpires && dateExpires < timeNow) || dateExpires === null) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const tokenInf = await getNewToken();

        if (tokenInf) {
          // set the new token and timeExpires
          api.dispatch(setExpiresOn(tokenInf.expiresOn));
          api.dispatch(setToken(tokenInf.token));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else {
    result = await baseQuery(args, api, extraOptions);
  }
  return result ? result : { data: null };
};

export default baseQueryWithReauth;
