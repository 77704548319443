import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "@/store/chartDataRequest/requestQuerySlice";
import { ChartDatabaseFields } from "@/types/chartdata/request";
import { checkTextIsNumber } from "@/utilities/validators";
import { Form, TextControl } from "@/components/Form";
import {
  executeChartRequestTrigger,
  setExecuteChartData,
} from "@/store/chartDataRequest/chartTriggerSlice";
import { getRequestQuery } from "@/store/chartDataRequest/requestQuerySlice";

interface FilterField {
  label: ChartDatabaseFields;
  typeForm: "text" | "daterange";
  dataType?: "number" | "string";
}

const filterFields: FilterField[] = [
  {
    typeForm: "text",
    label: "uid",
    dataType: "number",
  },
  {
    typeForm: "text",
    label: "lid",
    dataType: "number",
  },
  {
    typeForm: "text",
    label: "fileName",
    dataType: "string",
  },
  {
    typeForm: "text",
    label: "country",
    dataType: "string",
  },
  {
    typeForm: "text",
    label: "admiraltyName",
    dataType: "string",
  },
  {
    typeForm: "text",
    label: "insertCode",
    dataType: "string",
  },
  {
    typeForm: "text",
    label: "lastId",
    dataType: "number",
  },
  {
    typeForm: "daterange",
    label: "created",
    dataType: "string",
  },
  {
    typeForm: "daterange",
    label: "updated",
    dataType: "string",
  },
  {
    typeForm: "daterange",
    label: "deleted",
    dataType: "string",
  },
];

const ChartDBForm = () => {
  const dispatch = useDispatch();

  const filterBy = useSelector(getRequestQuery);

  const [filtersDict, setFiltersDict] = React.useState(filterBy.filterBy);

  const isFetched = useSelector(executeChartRequestTrigger);

  const submitForm = () => {
    dispatch(setFilters(filtersDict));
    dispatch(setExecuteChartData(true));
  };

  return (
    <Form
      title={"Request Chart Data"}
      disabled={isFetched}
      buttonTitle={"Submit"}
      submitForm={submitForm}
      testId={"chart-data-form"}
    >
      {filterFields.map((field: FilterField) => {
        if (field.typeForm === "text") {
          return (
            <TextControl
              key={field.label}
              label={field.label}
              disabled={isFetched}
              valueChange={(text: string | number | null) => {
                if (text === "") {
                  text = null;
                }
                setFiltersDict({
                  ...filtersDict,
                  [field.label]: text,
                });
              }}
              value={filtersDict[field.label]}
              error={() => {
                if (field.dataType === "number" && filtersDict[field.label]) {
                  return checkTextIsNumber(filtersDict[field.label]);
                } else {
                  return false;
                }
              }}
              helperText={() => {
                if (
                  field.dataType === "number" &&
                  filtersDict[field.label] &&
                  checkTextIsNumber(filtersDict[field.label])
                ) {
                  return "Must be a number";
                } else {
                  return undefined;
                }
              }}
            />
          );
        }
        return (
          <TextControl
            key={field.label}
            label={field.label}
            disabled={isFetched}
            valueChange={(text: string | number) => {
              setFiltersDict({
                ...filtersDict,
                [field.label]: text,
              });
            }}
          />
        );
      })}
    </Form>
  );
};

export default ChartDBForm;
