import React from "react";
import { Link } from "react-router-dom";

interface MailToProps {
  email: string;
  label: string;
}

// Get from here https://stackoverflow.com/questions/63782544/react-open-mailto-e-mail-client-onclick-with-body-from-textarea
const MailTo: React.FC<MailToProps> = ({ email, label }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = `mailto:${email}`;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default MailTo;
