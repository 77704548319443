import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldName } from "@/types/tables/fieldName";
import { useCreateTableDataMutation } from "@/store/chartDataRequest/chartDataApi";
import {
  getRequestQuery,
  setSort,
  setPagination,
} from "@/store/chartDataRequest/requestQuerySlice";
import { Table, TableBody, TableFooter, TableHead } from "@/components/Table/";
import { SORT_DIRECTION } from "@/types/chartdata/request";
import {
  executeChartRequestTrigger,
  setExecuteChartData,
} from "@/store/chartDataRequest/chartTriggerSlice";
import { setChartAdditional } from "@/store/chartDataRequest/chartAdditionalSlice";
import {
  setFileNameChart,
  setUidChart,
} from "@/store/chartDataRequest/selectedChartSlice";

const fieldNames: FieldName[] = [
  {
    name: "uid",
    alias: "UID",
    type: "number",
  },
  {
    name: "lid",
    alias: "LID",
    type: "number",
  },
  {
    name: "fileName",
    alias: "Filename",
    type: "string",
  },
  {
    name: "country",
    alias: "Country",
    type: "string",
  },
  {
    name: "admiraltyName",
    alias: "Admiralty Name",
    type: "string",
  },
  {
    name: "insertCode",
    alias: "Insert Code",
    type: "string",
  },
  {
    name: "lastId",
    alias: "LastID",
    type: "number",
  },
  {
    name: "created",
    alias: "Created",
    type: "date",
  },
  {
    name: "updated",
    alias: "Updated",
    type: "date",
  },
  {
    name: "deleted",
    alias: "Deleted",
    type: "date",
  },
];

const ChartDBTable = () => {
  const executeTrigger = useSelector(executeChartRequestTrigger);
  const queryResult = useSelector(getRequestQuery);
  const dispatch = useDispatch();

  const [
    getDatabaseByFilter,
    {
      data: chartData,
      isLoading: isChartDataLoading,
      isError: isChartDataError,
    },
  ] = useCreateTableDataMutation({
    fixedCacheKey: "chartData",
  });

  React.useEffect(() => {
    if (!executeTrigger) return;
    getDatabaseByFilter(queryResult);
    dispatch(setExecuteChartData(false));
  }, [queryResult, getDatabaseByFilter, executeTrigger, dispatch]);

  return (
    <Table
      title={"Chart Database"}
      testId={"chart-table"}
      isLoading={isChartDataLoading}
      isError={isChartDataError}
      tableFooter={
        <TableFooter
          pageOrigin={queryResult.page.number - 1}
          rowsPerPageOrigin={queryResult.page.size}
          totalRows={chartData ? chartData.totalCount : 0}
          rowsPerPageOptions={[20, 35, 50]}
          changePage={(newPage) => {
            dispatch(
              setPagination({ ...queryResult.page, number: newPage + 1 })
            );
            dispatch(setExecuteChartData(true));
          }}
          changeRowsPerPage={(rowsPerPage) => {
            dispatch(setPagination({ ...queryResult.page, size: rowsPerPage }));
            dispatch(setExecuteChartData(true));
          }}
        />
      }
    >
      <TableHead
        fieldNames={fieldNames}
        sortDirection={queryResult.sort}
        handleSort={(sortName) => {
          let copySort = { ...queryResult.sort };
          if (copySort.by === sortName) {
            if (copySort.order === SORT_DIRECTION.Asc) {
              copySort.order = SORT_DIRECTION.Desc;
            } else {
              copySort.order = SORT_DIRECTION.Asc;
            }
          } else {
            copySort.by = sortName;
            copySort.order = SORT_DIRECTION.Asc;
          }
          dispatch(setSort(copySort));
          dispatch(setExecuteChartData(true));
        }}
      />
      {chartData && (
        <TableBody
          data={chartData.charts}
          fieldNames={fieldNames}
          testTitle={"chart"}
          hover={true}
          selectAction={(id: number) => {
            dispatch(
              setChartAdditional({
                filename: chartData.charts[id].fileName,
                id: chartData.charts[id].id,
                lastUid: chartData.charts[id].lastUid,
                description: chartData.charts[id].description,
                latB: chartData.charts[id].latB,
                latE: chartData.charts[id].latE,
                lonB: chartData.charts[id].lonB,
                lonE: chartData.charts[id].lonE,
                dLat: chartData.charts[id].dLat,
                dLon: chartData.charts[id].dLon,
                scale: chartData.charts[id].scale,
                type: chartData.charts[id].type,
                issued: chartData.charts[id].issued,
                printed: chartData.charts[id].printed,
              })
            );
            dispatch(setFileNameChart(chartData.charts[id].fileName));
            dispatch(setUidChart(chartData.charts[id].uid));
          }}
        />
      )}
    </Table>
  );
};

export default ChartDBTable;
