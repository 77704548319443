import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";

interface ChartSlice {
  fileName: string | null;
  uid: number | null;
}

const initialState: ChartSlice = {
  fileName: null,
  uid: null,
};

const selectedChartSlice = createSlice({
  name: "selectedChartSlice",
  initialState,
  reducers: {
    setFileNameChart: (state, action) => {
      state.fileName = action.payload;
    },
    setUidChart: (state, action) => {
      state.uid = action.payload;
    },
  },
});

export const { setFileNameChart, setUidChart } = selectedChartSlice.actions;
export default selectedChartSlice.reducer;

export const selectedChartByFileName = (state: RootState) =>
  state.selectedChart.fileName;
export const selectedChartByUid = (state: RootState) => state.selectedChart.uid;
