import { msalInstance } from "../msalInstance";
import { loginRequest } from "../../authConfig";

export async function getNewToken() {
  const account = msalInstance.getAllAccounts()[0];
  const request = {
    ...loginRequest,
    account: account,
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((accessTokenResponse) => {
      let accessToken = accessTokenResponse.accessToken;
      let expiresOn = accessTokenResponse.expiresOn?.getTime();
      return {
        token: accessToken,
        expiresOn: expiresOn,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}
