import React from "react";
import {
  TableContainer,
  Table,
  Box,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

interface TableMainProps {
  title: string;
  testId: string;
  isLoading: boolean;
  isError: boolean;
  refetchData?: () => void;
  children: React.ReactNode;
  maxHeight?: string;
  tableFooter?: React.ReactElement;
  tableSize?: "small" | "medium";
}

const TableMain: React.FC<TableMainProps> = ({
  title,
  testId = "test-table-id",
  isLoading,
  isError,
  refetchData,
  children,
  tableFooter,
  tableSize,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "40px",
          mb: 2,
        }}
      >
        <Typography component="div" variant="h5">
          {title}
        </Typography>
        {refetchData && (
          <IconButton data-testid={`${testId}-refresh`} onClick={refetchData}>
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
      {isLoading && (
        <Typography
          component="div"
          variant="h5"
          data-testid={`${testId}-loading`}
        >
          Loading...
        </Typography>
      )}
      {isError && (
        <Typography
          component="div"
          variant="h5"
          data-testid={`${testId}-error`}
        >
          Error
        </Typography>
      )}
      {!isLoading && !isError && (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxWidth: "lg" }}>
            <Table
              data-testid={testId}
              stickyHeader
              size={tableSize || undefined}
            >
              {children}
            </Table>
          </TableContainer>
          {tableFooter}
        </Paper>
      )}
    </Box>
  );
};

export default TableMain;
