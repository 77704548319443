import { useSelector } from "react-redux";
import { Table, TableHead, TableBody } from "@/components/Table";
import { FieldName } from "@/types/tables/fieldName";
import { chartAdditional } from "@/store/chartDataRequest/chartAdditionalSlice";

export const fieldNames: FieldName[] = [
  {
    name: "id",
    alias: "ID",
    type: "number",
  },
  {
    name: "description",
    alias: "Description",
    type: "string",
  },
  {
    name: "latB",
    alias: "Lat B",
    type: "number",
  },
  {
    name: "latE",
    alias: "Lat E",
    type: "number",
  },
  {
    name: "lonB",
    alias: "Lon B",
    type: "number",
  },
  {
    name: "lonE",
    alias: "Lon E",
    type: "number",
  },
  {
    name: "scale",
    alias: "Scale",
    type: "number",
  },
  {
    name: "type",
    alias: "Type",
    type: "number",
  },
  {
    name: "issued",
    alias: "Issued",
    type: "date",
  },
  {
    name: "printed",
    alias: "Printed",
    type: "date",
  },
];

const ChartDBAddInf = () => {
  const dataAdditional = useSelector(chartAdditional);

  return (
    <Table
      title="Chart Additional"
      testId="test-table-history"
      isLoading={false}
      isError={false}
    >
      <TableHead fieldNames={fieldNames} />
      {dataAdditional &&
        Object.values(dataAdditional).every((value) => value !== null) && (
          <TableBody fieldNames={fieldNames} data={[dataAdditional]} />
        )}
    </Table>
  );
};

export default ChartDBAddInf;
