import RouteTab from "@/types/routes/routes";
import routesDict from "@/utilities/routes/routesDict";
import { Routes, Route } from "react-router-dom";

// may be when we have a home page, we can add it routesDict
import IssueMain from "@/pages/IssuePage/IssueMain";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route key={"home"} path={"/"} element={<IssueMain />} />
      {routesDict.map((route: RouteTab) => (
        <Route
          key={route.key}
          path={route.path}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
};

export default RoutesComponent;
