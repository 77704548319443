import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface AccordionMainProps {
  title: string;
  children: React.ReactNode;
}

const AccordionMain: React.FC<AccordionMainProps> = ({ title, children }) => {
  return (
    <Paper sx={{ my: 1 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={`panel1a-header-${title}`}
        >
          <Typography variant="body1">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ maxHeight: "100px", overflowY: "auto" }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default AccordionMain;
