import React from "react";
import { Box, Grid } from "@mui/material";

interface PageGridProps {
  control: React.ReactElement;
  main: React.ReactElement;
  details: React.ReactElement;
  ratio_array?: number[];
}

const PageGrid = (props: PageGridProps) => {
  const ratio = props.ratio_array || [2, 6, 4];

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        m: 1,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={4} md={ratio[0]}>
          {props.control}
        </Grid>
        <Grid item xs={4} md={ratio[1]}>
          {props.main}
        </Grid>
        <Grid item xs={4} md={ratio[2]}>
          {props.details}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageGrid;
