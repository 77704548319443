import { createApi } from "@reduxjs/toolkit/query/react";
import {
  Issue,
  IssueCreationData,
  IssueStatus,
  IssueSummaryRow,
} from "@/types/types";
import baseQueryWithReauth from "@/utilities/store/customFetchBase";

export type IssueCreationDataRequest = Omit<IssueCreationData, "weekNumber">;

interface IssueCreationDataReponse {
  isBusy: boolean;
  issueRequests: Issue[];
}

export const issuesApi = createApi({
  reducerPath: "issuesApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getIssues: build.query<Issue[], void>({
      query: () => ({
        url: "/issue-request",
        method: "GET",
      }),
    }),
    createIssue: build.mutation<void, IssueCreationData>({
      query: (body) => ({
        url: "/issue-request",
        method: "POST",
        body,
      }),
    }),
    canCreateIssue: build.query<
      IssueCreationDataReponse,
      IssueCreationDataRequest
    >({
      query: (creationData: IssueCreationDataRequest) => ({
        url: "/issue-request/can-create",
        method: "GET",
        params: {
          type: creationData.type,
          status: creationData.status,
        },
      }),
    }),
    unlockIssue: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/issue-request/${id}/unlock`,
        method: "PUT",
      }),
    }),
    cancelIssue: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/issue-request/${id}/cancel`,
        method: "PUT",
      }),
    }),
    getIssueSummary: build.query<IssueSummaryRow[], void>({
      query: () => ({
        url: "/issue-request/current-issue/summary",
        method: "GET",
      }),
    }),
    getIssueStatuses: build.query<IssueStatus[], number>({
      query: (issueId: number) => ({
        url: `/issue-request/${issueId}/status`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetIssuesQuery,
  useLazyCanCreateIssueQuery,
  useGetIssueSummaryQuery,
  useLazyGetIssueStatusesQuery,
  useCreateIssueMutation,
  useUnlockIssueMutation,
  useCancelIssueMutation,
} = issuesApi;
