import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "@/authConfig";
import { Button } from "@mui/material";

function handleLogin(instance: IPublicClientApplication) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}

const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button color="inherit" onClick={() => handleLogin(instance)}>
      Login
    </Button>
  );
};

export default SignInButton;
