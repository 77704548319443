import { Typography } from "@mui/material";
import { dateTimeReformat } from "@/utilities/share";

interface ValueInComponentProps {
  type: "string" | "number" | "date";
  value: string | number;
  color?: string;
  align?: string;
}

const valueSwitch = (value: string | number, type: string) => {
  if (value === null) return "-";
  switch (type) {
    case "date":
      return dateTimeReformat(value as string);
    case "string":
      return value;
    case "number":
      return value;
    default:
      return value;
  }
};

const ValueInComponent: React.FC<ValueInComponentProps> = ({
  value,
  color = "text.primary",
  align = "center",
  type = "string",
}) => {
  return (
    <Typography
      variant={"body2"}
      component={"div"}
      color={color}
      sx={{
        textAlign: align,
      }}
    >
      {valueSwitch(value, type)}
    </Typography>
  );
};

export default ValueInComponent;
