import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);

const getCurrentWeek: () => number = () => {
  const week = dayjs(Date.now()).week();
  return week;
};

export default getCurrentWeek;
