import { Typography, Box } from "@mui/material";
import { MailTo } from "@/components/Text";

const UnauthenticatedPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignText: "center",
      }}
    >
      <Typography variant="h3" sx={{ marginBottom: "0.5rem" }}>
        Chart Issue Manager
      </Typography>
      <Typography sx={{ marginBottom: "0.5rem" }}>
        Please login from the navigation bar to use the application.
      </Typography>
      <Typography>
        If you are unable to login and need additional permissions to use this
        application, please contact{" "}
        <MailTo
          email={" wv.dssupport@wartsila.com"}
          label={"wv.dssupport@wartsila.com"}
        />{" "}
        for assistance.
      </Typography>
    </Box>
  );
};

export default UnauthenticatedPage;
