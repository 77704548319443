import { PanelContainer, PageGrid } from "@/components/Layout";
import IssueSummary from "@/pages/IssuePage/IssueSummary/IssueSummary";
import IssueForm from "@/pages/IssuePage/IssueForm/IssueForm";
import IssueTable from "@/pages/IssuePage/IssueTable/IssueTable";

const IssueMain = () => {
  return (
    <PageGrid
      ratio_array={[2, 8, 2]}
      control={
        <PanelContainer>
          <IssueForm />
        </PanelContainer>
      }
      main={
        <PanelContainer>
          <IssueTable />
        </PanelContainer>
      }
      details={
        <PanelContainer>
          <IssueSummary />
        </PanelContainer>
      }
    />
  );
};

export default IssueMain;
