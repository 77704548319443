import * as React from "react";
import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function toastWrapper(
  type: "success" | "info" | "warn" | "error",
  message: string | React.ReactNode,
  options?: ToastOptions
) {
  const opts = options
    ? options
    : {
        autoClose: 6000,
        hideProgressBar: true,
      };
  return toast[type](message, opts);
}

export const defaultErrorMsg = "An error occurred. Please try again.";

export function showSuccessMessage(
  message: string | React.ReactNode,
  options?: ToastOptions
) {
  return toastWrapper("success", message, options);
}

export function showErrorMessage(
  message: string | React.ReactNode = defaultErrorMsg,
  options?: ToastOptions
) {
  return toastWrapper("error", message, options);
}

export function showWarningMessage(
  message: string | React.ReactNode,
  options?: ToastOptions
) {
  return toastWrapper("warn", message, options);
}

export function showInfoMessage(
  message: string | React.ReactNode,
  options?: ToastOptions
) {
  return toastWrapper("info", message, options);
}
