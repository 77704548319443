import { FC } from "react";
import { IconButton, TableCell, Box } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Sort from "@mui/icons-material/Sort";
import { SORT_DIRECTION } from "@/types/chartdata/request";
import { FieldName } from "@/types/tables/fieldName";

interface HeaderCellProps {
  fieldName: FieldName;
  sortDirection?: SORT_DIRECTION | "unused";
  handleSort?: (sortField: string) => void;
}

const TableHeaderCell: FC<HeaderCellProps> = ({
  fieldName,
  sortDirection,
  handleSort,
}) => {
  return (
    <TableCell sx={{ p: 0 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0,
        }}
      >
        {fieldName.alias}{" "}
        {sortDirection && (
          <IconButton
            size="small"
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={false}
            aria-haspopup="true"
            onClick={handleSort ? () => handleSort(fieldName.name) : undefined}
          >
            {sortDirection === SORT_DIRECTION.Asc && <ArrowDropUpIcon />}
            {sortDirection === SORT_DIRECTION.Desc && <ArrowDropDownIcon />}
            {sortDirection === "unused" && <Sort />}
          </IconButton>
        )}
      </Box>
    </TableCell>
  );
};

export default TableHeaderCell;
