import { createSlice } from "@reduxjs/toolkit";
import { ChartDatabaseRequestFields } from "@/types/chartdata/request";
import { RootState } from "@/store";

export interface ChartAdditionalSlice {
  data: ChartDatabaseRequestFields;
}

const initialState: ChartAdditionalSlice = {
  data: {
    id: null,
    lastUid: null,
    description: null,
    latB: null,
    latE: null,
    lonB: null,
    lonE: null,
    dLat: null,
    dLon: null,
    scale: null,
    type: null,
    issued: null,
    printed: null,
  },
};

const chartAdditionalSlice = createSlice({
  name: "chartAdditionalSlice",
  initialState,
  reducers: {
    setChartAdditional: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setChartAdditional } = chartAdditionalSlice.actions;
export default chartAdditionalSlice.reducer;
export const chartAdditional = (state: RootState) => state.chartAdditional.data;
