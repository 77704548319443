import { useIsAuthenticated } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import { Header } from "@/components/NavBar";
import { Routes } from "@/components/Routes";
import UnauthenticatedPage from "./UnauthenticatedPage";

const Layout = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Header />
      <main>
        {isAuthenticated && (
          <>
            <Routes />
            <ToastContainer />
          </>
        )}
        {!isAuthenticated && <UnauthenticatedPage />}
      </main>
    </>
  );
};

export default Layout;
