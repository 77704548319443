import React from "react";
import { Button as MuiButton } from "@mui/material";

interface ButtonProps {
  title: string;
  onClickState: () => void;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const handleValueChange = () => {
    props.onClickState();
  };

  return (
    <MuiButton
      variant="outlined"
      onClick={handleValueChange}
      color={props.color}
    >
      {props.title}
    </MuiButton>
  );
};

export default Button;
