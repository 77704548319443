// TODO: use enums

export enum ISSUE_TYPE {
  "Senc" = "Senc",
  "Tx97" = "Tx97",
}

export enum ISSUE_STATUS {
  "Online" = "Online",
  "Base" = "Base",
}

export enum ISSUE_STATE {
  "New" = "New",
  "InProgress" = "InProgress",
  "ReadyForTesting" = "ReadyForTesting",
  "Completed" = "Completed",
  "Canceled" = "Canceled",
  "Failed" = "Failed",
}

export interface IssueCreationData {
  type: ISSUE_TYPE;
  status: ISSUE_STATUS;
  weekNumber: number;
}

export interface Issue extends IssueCreationData {
  id: number;
  issueRequestId: number;
  issueId: number | null;
  state: ISSUE_STATE;
  created: string;
  finished: string | null;
  createdBy: string;
  cancelledBy: string | null;
  details: string | null;
  updated?: string | null;
}

export interface IssueSummaryRow {
  issueHistoryRecordId: number;
  name: string;
  details: string;
  infoCreated: string;
}

export type IssuePropsDetail = Omit<
  Issue,
  | "created"
  | "id"
  | "issueId"
  | "finished"
  | "createdBy"
  | "cancelledBy"
  | "status"
  | "weekNumber"
  | "type"
  | "updated"
>;

export interface IssueStatus {
  id: number;
  issueRequestId: number;
  operationInfo: string;
  operationType: "Process" | "Status";
  issueType: ISSUE_TYPE;
  progress: number;
  updateTime: string;
}
