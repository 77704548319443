import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { issuesApi } from "@/store/issuesRequest/issuesApi";
import authSlice from "@/store/authBackend/tokenInf";
import refetchSlice from "@/store/issuesRequest/refetchSlice";
import detailsSlice from "@/store/issuesRequest/detailsSlice";
import { chartDataApi } from "@/store/chartDataRequest/chartDataApi";
import chartDataRequestSlice from "@/store/chartDataRequest/requestQuerySlice";
import chartRequestTrigger from "@/store/chartDataRequest/chartTriggerSlice";
import chartAdditionalSlice from "@/store/chartDataRequest/chartAdditionalSlice";
import selectrdChartSlice from "@/store/chartDataRequest/selectedChartSlice";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      [issuesApi.reducerPath]: issuesApi.reducer,
      [chartDataApi.reducerPath]: chartDataApi.reducer,
      auth: authSlice,
      refetchIssue: refetchSlice,
      detailsFetch: detailsSlice,
      chartDataRequest: chartDataRequestSlice,
      chartRequestTrigger: chartRequestTrigger,
      chartAdditional: chartAdditionalSlice,
      selectedChart: selectrdChartSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        issuesApi.middleware,
        chartDataApi.middleware
      ),
    ...options,
  });

export const index = createStore();
export type AppDispatch = typeof index.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof index.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
