import PageGrid from "@/components/Layout/PageGrid";
import ChartDBForm from "@/pages/ChartDatabase/ChartDBForm/ChartDBForm";
import { PanelContainer } from "@/components/Layout";
import ChartDBTable from "@/pages/ChartDatabase/ChartDBTable/ChartDBTable";
import ChartDBAddInf from "@/pages/ChartDatabase/ChartDBAddInf/ChartDBAddInf";
import ChartDBHistory from "@/pages/ChartDatabase/ChartDBHistory/ChartDBHistory";

const ChartDBMain = () => {
  return (
    <PageGrid
      ratio_array={[2, 5, 5]}
      control={
        <PanelContainer>
          <ChartDBForm />
        </PanelContainer>
      }
      main={
        <PanelContainer>
          <ChartDBTable />
        </PanelContainer>
      }
      details={
        <PanelContainer>
          <ChartDBAddInf />
          <ChartDBHistory />
        </PanelContainer>
      }
    />
  );
};

export default ChartDBMain;
