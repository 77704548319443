import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";

interface ChartTriggerSlice {
  executeChartData: boolean;
}

const initialState: ChartTriggerSlice = {
  executeChartData: false,
};

const chartRequestTrigger = createSlice({
  name: "chartRequestTrigger",
  initialState,
  reducers: {
    setExecuteChartData: (state, action) => {
      state.executeChartData = action.payload;
    },
  },
});

export const { setExecuteChartData } = chartRequestTrigger.actions;
export default chartRequestTrigger.reducer;
export const executeChartRequestTrigger = (state: RootState) =>
  state.chartRequestTrigger.executeChartData;
