import { Box, Typography, Button } from "@mui/material";
import React from "react";

interface FormProps {
  title: string;
  disabled: boolean;
  children: React.ReactElement[];
  buttonTitle: string;
  submitForm: () => void;
  testId: string;
}

const FormMain: React.FC<FormProps> = ({
  title,
  disabled,
  children,
  buttonTitle,
  submitForm,
  testId = "test-form",
}) => {
  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <Box
      data-testid="form-chart-data"
      component="form"
      onSubmit={handleSubmitForm}
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 4,
      }}
    >
      <Typography
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "40px",
        }}
        variant="h5"
        component="h5"
        data-testid={testId}
      >
        {" "}
        {title}{" "}
      </Typography>
      {children}
      <Button
        type="submit"
        variant="contained"
        disabled={disabled}
        data-testid={`${testId}-submit`}
      >
        {buttonTitle}
      </Button>
    </Box>
  );
};

export default FormMain;
